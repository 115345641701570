import ComponentWithFloatingIconDownload from '@/components/ComponentWithFloatingIconDownload';

export const POST_CARD_MODAL = 'POST_CARD_MODAL';

export default {
  name: 'AnalyticsPostModal',

  components: {
    ComponentWithFloatingIconDownload,
  },

  props: {
    accountImage: {
      required: true,
      type: String,
    },
    accountName: {
      required: true,
      type: String,
    },
    brandUrl: {
      required: true,
      type: String,
    },
    brandId: {
      type: String,
    },
    post: {
      required: true,
      type: Object,
    },
    remoteAccount: {
      type: Object,
    },
    extraConfig: {
      default: null, //  {source: 'instagram', username: 'ig-username'}
      type: Object,
    },
    influencers: {
      default: () => [],
      type: Array,
    },
    storyMode: {
      default: false,
    },
  },
};
